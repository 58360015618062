import React, { FC, useCallback, memo, useMemo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import InfoCircle from '@brandandcelebrities/icons/regular/InfoCircle';
import { Button } from '@brandandcelebrities/kolkitten';
import { Loader } from '@brandandcelebrities/kolkit';

import { ON_ACCOUNT_DATAS, ON_DISCONNECTED } from 'utils/fbc/FacebookBusinessConnect';

import { PagesPicker, ConnectButton } from 'components/molecules';
import { Modal, InstagramButton } from 'components/atoms';
import Markdown from 'components/atoms/Markdown';

import { saveInstagramSelectedPages, saveUserToken } from 'actions/profile';
import { fetchStatsSharing } from 'actions/user';
import { fbc } from 'config/connect';

import { Networks } from 'constants/networks';
import { useDispatch, useSelector, useLexique } from 'utils/redux';
import { FBCDataState } from 'reducers/fbc';
import { AppState } from 'reducers';
import { fetchFbc, reset, onConnect, onAccountDataReceived, onError, onSelect } from 'actions/fbc';
import { Pages, Page } from 'types/Instaconnect';
import InstaConnectLexique from 'locales/types/components/instaconnect';
import { SnasState } from 'reducers/snas';

import './InstaConnect.scss';

interface Analytics {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

export interface Lexique {
  title: string;
  text?: string | React.ReactElement;
  submit: string;
}

export interface Props {
  onClose?: (...args) => void;
  onSubmit?: (...args) => void;
  lexique: Lexique;
  analytics: {
    onSubmit: Analytics;
    onClose?: Analytics;
  };
  isPinkButton?: boolean;
  className?: string;
  modalOpened?: boolean;
  isRequestedFromBrand?: boolean;
}

const InstaConnect: FC<Props> = ({
                                   lexique,
                                   onSubmit,
                                   onClose,
                                   analytics,
                                   modalOpened,
                                   className,
                                   isPinkButton,
                                   isRequestedFromBrand,
                                 }) => {
  const dispatch = useDispatch();

  const { pages, selectedPages, loading: fbcLoading, error }: FBCDataState = useSelector<FBCDataState>(
    ({ fbc }: AppState) => fbc,
  );
  const { instagram } = useSelector<SnasState>(({ snas }: AppState) => snas);
  const instaConnectLexique = useLexique<InstaConnectLexique>('components.instaconnect');
  const toastLexique = useLexique('components.toasts');
  const [state, setState] = useState({
    connected: false,
    loading: false,
  });

  const globalLoading = useMemo(
    () => state.loading || fbcLoading,
    [state.loading, fbcLoading]
  );

  const onCloseModal = useCallback(() => {
    onClose();
// GA
    if (analytics.onClose) {
      window.ga('send', 'event', analytics.onClose);
    }
  }, [onClose, analytics.onClose]);

  useEffect(() => {
    if (!modalOpened) dispatch(reset());
  }, [modalOpened, dispatch]);

  const onSubmitSendInstagramPages = useCallback(async (pages: Page[]) => {
      try {
// Send to back-end
        await dispatch(saveInstagramSelectedPages(pages));

// GA
        window.ga('send', 'event', analytics.onSubmit);

// update snas
        pages.map((page) =>
          dispatch(
            onConnect({
              ...page,
              label: Networks.instagram,
              url: `https://instagram.com/${page.username}`,
            }),
          ),
        );
        toast(toastLexique.instaConnect);
        onSubmit();
      } catch (err) {
        dispatch(onError(err));
      }
    }, [dispatch, analytics.onSubmit, onSubmit, toastLexique],
  );

  const handleSubmit = useCallback(async (pages) => {
    setState(s => ({...s, loading: true}));
    await onSubmitSendInstagramPages(pages.instagram);
    await dispatch(saveUserToken(pages));
    setState({connected: true, loading: false});
  }, [onSubmitSendInstagramPages, dispatch]);

  const submitPagesPicker = useCallback(async () => {
    if (selectedPages.instagram.length > 0) {
      handleSubmit(selectedPages);
    }
  }, [handleSubmit, selectedPages]);

  const getFBCData = useCallback(
    async (pages: Pages) => {
      try {
        if (!pages.instagram || pages.instagram?.length === 0) {
          throw new Error('No_pages');
        }
        if (pages.accessToken) {
          await dispatch(fetchStatsSharing());
          if (pages.instagram?.length > 1) {
            await dispatch(onAccountDataReceived(pages));
          } else {
            await dispatch(
              onSelect({
                network: Networks.instagram,
                pages: pages.instagram,
              }),
            );
            handleSubmit(pages);
          }
        } else {
          throw new Error('Not logged in');
        }
      } catch (e) {
        dispatch(onError(e));
      }
    },
    [dispatch, handleSubmit],
  );

  const fail = useCallback(
    (e) => {
      dispatch(onError(e.reason));
    },
    [dispatch],
  );

  const getFacebookPages = useCallback(async () => {
    await dispatch(fetchFbc());
    fbc.once(ON_ACCOUNT_DATAS, getFBCData);
    fbc.once(ON_DISCONNECTED, fail);
    fbc.requestDatas();
  }, [dispatch, getFBCData, fail]);

  const selectPages = useCallback(
    (selected: string | number) => {
      const selectedPage = pages.instagram.find((p) => p.uid === selected);
      dispatch(
        onSelect({
          network: Networks.instagram,
          pages: [selectedPage] || [],
        }),
      );
    },
    [dispatch, pages],
  );

  const content = useMemo(
    () => (
      <div className="instaconnect">
        <div className="instaconnect__title">
          <Markdown>{lexique.title}</Markdown>
        </div>
        {lexique.text && !error ? (
          <div className="instaconnect__text">
            {typeof lexique.text === 'string' ? <Markdown>{lexique.text}</Markdown> : lexique.text}
          </div>
        ) : null}
        {error ? (
          <div className="insta-business_no-page">
            <div className="insta-business_no-page_square">
              <div className="insta-business_no-page_icon">
                <InfoCircle />
              </div>
              <div className="insta-business_no-page_text">{instaConnectLexique.errorConnect}</div>
            </div>
          </div>
        ) : null}
        <div className="instaconnect__button">
          {!pages.instagram.length && !isPinkButton && !globalLoading ? (
            <ConnectButton
              onClickConnect={getFacebookPages}
              label={instaConnectLexique.modal.new.addInstagramBusiness}
            />
          ) : null}
          {!pages.instagram.length && isPinkButton && !globalLoading ? (
            <>
              <InstagramButton
                onClick={getFacebookPages}
                lexique={
                  instaConnectLexique[
                    isRequestedFromBrand ? 'consentInstagramButton' : 'instagramButton'
                    ]
                }
              />
              <div className="instaconnect__warning">
                <p>{instaConnectLexique.modal.new[isRequestedFromBrand ? 'consentWarning' : 'warning']}</p>
              </div>
            </>
          ) : null}

          {
            pages.instagram.length > 1 && (
              <>
                <PagesPicker
                  lexique={instaConnectLexique.selectPages.instagram}
                  pages={pages.instagram}
                  selected={selectedPages.instagram?.[0]?.uid}
                  onChange={selectPages}
                />
                <div className="tac mb20">
                  <Button
                    id="fbc-validate-pages"
                    label={lexique.submit}
                    onClick={submitPagesPicker}
                    disabled={selectedPages.instagram.length === 0}
                  />
                </div>
              </>
            )
          }
        </div>
        {!isPinkButton && (
          <div className="instaconnect__gnee">
            <a
              href={instaConnectLexique.selectPages.instagram.link.url}
              target="_blank"
              className="instaconnect__gnee__link"
              rel="noopener noreferrer"
            >
              {instaConnectLexique.selectPages.instagram.link.label}
            </a>
          </div>
        )}
      </div>
    ),
    [
      lexique,
      error,
      pages,
      isPinkButton,
      getFacebookPages,
      selectPages,
      instaConnectLexique,
      globalLoading,
      selectedPages.instagram,
      submitPagesPicker,
      isRequestedFromBrand,
    ],
  );

// when connected, display it!
  const connectedContent = useMemo(
    () => (
      <div className="instaconnect__congratulations">
        <h3>
          {instagram[0] && instaConnectLexique.congratulations.title.replace('##', instagram[0].username)}
        </h3>
        <p>{instaConnectLexique.congratulations.text}</p>
      </div>
    ),
    [instagram, instaConnectLexique],
  );

  return (
    <Modal onClose={onCloseModal} display={modalOpened} className={className || ''}>
      {globalLoading && <Loader full />}
      {state.connected ? connectedContent : content}
    </Modal>
  );
};

InstaConnect.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  modalOpened: false,
  isRequestedFromBrand: false,
};

export default memo(InstaConnect);
