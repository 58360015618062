import axios, { AxiosPromise } from 'axios';
import moment from 'moment';
import { showModale } from 'actions/modale';
import { showUpgradeModal } from 'actions/ui';
import { PER_PAGE } from 'constants/ui';

import { TimeRange, SocialNetworksFilter, GlobalFilters, SNATopPostsParams } from 'types/Filters';
import { SNAAPIData, Networks } from 'types/snas';
import { getTimeRangeParameters } from 'utils/timeRange';

import {
    FetchInitApp,
    FacebookPagesParams,
    FetchStatsSharing,
    FetchRequestStatsSharingInfos,
    FetchConversations,
    FetchConversationInfos,
    FetchAllMessages,
    FetchAllMessagesParams,
    FetchMessage,
    FetchDraftMessage,
    FetchDraftMessages,
} from 'types/fetchApi';
import { ApiPromise, Api, APIIDResponsePromise, APIResponseDataPromise } from './api.d';
import { store, getDispatcher } from './store';
import { getHeaders } from './index';
import { conf, reactAppVersion } from '../config/env';
import { appsignal } from 'config/appsignal';


const axiosI = axios.create();
const axiosIwithCredentials = axios.create({withCredentials: true});

axiosI.interceptors.response.use(
    (response) => {
        const dispatch = getDispatcher();
        if (response.headers['x-server-version'] !== reactAppVersion) {
            if (!store.getState().ui.modals.upgradeModal.display) dispatch(showUpgradeModal());
        }
        return response;
    },
    (error) => {
        const dispatch = getDispatcher();
        if(error?.response?.status){
            switch (error.response.status) {
                case 412:
                    dispatch(
                        showModale({
                            body: 'BlockingUpgrade',
                        }),
                    );
                    break;
                case 503:
                    dispatch(
                        showModale({
                            body: 'BlockingMaintenance',
                        }),
                    );
                    break;
                case 401:
                case 403:
                    break;
                default: {
                    const { userId, firstName, lastName } = store.getState().user;
                    appsignal.sendError(error, (span) => {
                        span.setTags({
                            id: userId,
                            name: `${firstName} ${lastName}`,
                            api_status: error.response.status,
                            currentUrl: window.location.href,
                            error,
                        });
                    });
                }
            }
        }
        return Promise.reject(error);
    },
);

const getAccountIdsParameters = (socialNetworks: SocialNetworksFilter) => {
    const networks = Object.keys(socialNetworks).map((key) => socialNetworks[key]);
    const snaFiltered = networks.filter(({ selected }) => selected === true);

    return snaFiltered.length === 0 ? networks.map((item) => item.id) : snaFiltered.map((item) => item.id);
};

export const fetchHeader = () => axiosI.get(`${conf.api}/analytics/summary`, getHeaders());

export const fetchPosts = (token, snas, globalFilters, searchCardFilters, page = 0) => {
    const { start_at, end_at } = getTimeRangeParameters(globalFilters.timeRange);

    const account_ids = getAccountIdsParameters(globalFilters.socialNetworksFilter);

    return axiosI.get(`${conf.api}/posts`, {
        headers: getHeaders().headers,
        params: {
            content: searchCardFilters.search,
            account_ids,
            sort_by: searchCardFilters.sortFilter.selectedOption,
            start_at,
            end_at,
            page,
            per_page: PER_PAGE,
        },
    });
};

export const fetchCommunity = () => axiosI.get(`${conf.api}/analytics/audience`, getHeaders());

export const fetchSnaDataChart = (uid, filter: TimeRange = TimeRange.oneYear) => {
    const { start_at, end_at } = getTimeRangeParameters(filter, true);
    return axiosI.get(`${conf.api}/snas/${uid}/stats`, {
        headers: getHeaders().headers,
        params: {
            start_at,
            end_at,
        },
    });
};

export const login = (data) =>
    axiosIwithCredentials.post(`${conf.apiRoot}/v1/users/login`, data, getHeaders());

export const register = (data) =>
    axiosIwithCredentials.post(`${conf.apiRoot}/v1/users/register/influencer`, data, getHeaders());

export const verifyToken = (params) =>
    axiosI.get(`${conf.apiRoot}/v1/tokens/verify`, {
        headers: getHeaders().headers,
        params,
    });

export const sendConfirmationRegister = params =>
    axiosIwithCredentials.get(`${conf.apiRoot}/v1/users/confirmation`, {
        headers: getHeaders().headers,
        params,
    });

export const sendNewPassword = (params) =>
    axiosIwithCredentials.put(`${conf.apiRoot}/v1/users/password`, params, getHeaders());

export const resetForgottenPassword = (email) =>
    axiosIwithCredentials.post(`${conf.apiRoot}/v1/users/password`, { email }, getHeaders());

export const deleteUserLogout = () =>
    axiosI.delete(`${conf.apiRoot}/v1/users/logout`, {
        headers: getHeaders().headers,
        withCredentials: true,
    });

export const postUnsupportedSnaRequest = (
    data, // check
) =>
    axiosI.put(
        `${conf.api}/profiles/self`,
        {
            [data.type]: data.url,
        },
        getHeaders(),
    );

export const removeUnsupportedSna = (network: Networks): APIIDResponsePromise<number> =>
    axiosI.put(
        `${conf.api}/profiles/self`,
        {
            [network]: '',
        },
        getHeaders(),
    );

export const postSnaRequest = (
    data, // check
    onboardingStep = null,
) =>
    axiosI.put(
        `${conf.api}/profiles/self`,
        {
            snas: [
                {
                    label: data.label,
                    uid: data.uid,
                    username: data.username,
                    picture_url: data.picture_url,
                },
            ],
            onboarding_current_step: onboardingStep,
        },
        getHeaders(),
    );

export const toggleUserMobilisationEnlisting = (
    enlisted: boolean, // check
) =>
    axiosI.put(
        `${conf.api}/profiles/self`,
        {
            enlisted,
        },
        getHeaders(),
    );

export const fetchInfoSna = (type: string, url: string, fast: boolean) =>
    axiosI.get(`${conf.api}/snas/validate`, {
        headers: getHeaders().headers,
        params: {
            type,
            url,
            fast,
        },
    });

export const postProfileRequest = (data) => axiosI.put(`${conf.api}/profiles/self`, data, getHeaders());

export const putUserUpdateLocale = (token, locale) =>
    axiosI.put(
        `${conf.apiRoot}/v1/users/update_locale`,
        {
            locale,
        },
        getHeaders(),
    );

export const fetchAppInit: () => APIResponseDataPromise<FetchInitApp> = () =>
    axiosI.get(`${conf.api}/app_init`, getHeaders());

export const postAnswer = (
    token,
    id: string,
    answer_value: string,
    answer_text: string, // check
) =>
    axiosI.post(
        `${conf.api}/propositions/${id}/answers`,
        {
            answer_value,
            answer_text,
        },
        getHeaders(),
    );

export const fetchProposition = (token, id) => axiosI.get(`${conf.api}/propositions/${id}`, getHeaders());

export const fetchSnas = () => axiosI.get<SNAAPIData[]>(`${conf.api}/snas`, getHeaders());

export const fetchSna = (uid: string): AxiosPromise<SNAAPIData> => axiosI.get(`${conf.api}/snas/${uid}`, getHeaders());

export const fetchProfileSelf = () => axiosI.get(`${conf.api}/profiles/self`, getHeaders());

export const putProfileSelf = (state, form) => axiosI.put(`${conf.api}/profiles/self`, form, getHeaders());

export const putEmail = (state, query) => axiosI.put(`${conf.apiRoot}/v1/users/update_email`, query, getHeaders());

export const resendConfirmationEmail = (email: string): AxiosPromise<void> => {
    return axiosI.post(`${conf.apiRoot}/v1/users/resend_confirmation`, { email }, getHeaders());
};

export const putSettingsChangePassword = (query) =>
    axiosI.put(`${conf.apiRoot}/v1/users/update_password`, query, {
        headers: getHeaders().headers,
        withCredentials: true,
    });

export const removeSna = (uid) => axios.delete(`${conf.api}/snas/${uid}`, getHeaders());

export const saveUserToken = (tokenData) => {
    const { uid, ...data } = tokenData;
    return axiosI.post(`${conf.api}/snas/${uid}/token`, data, getHeaders());
}

export const saveInstagramPages = (pages) => axiosI.put(`${conf.api}/profiles/self`, pages, getHeaders());

export const saveFacebookPages = (pages: FacebookPagesParams) => axiosI.put(`${conf.api}/profiles/self`, pages, getHeaders());

export const fetchPropositions = () => axiosI.get(`${conf.api}/propositions`, getHeaders());

export const postAnswerBrief = (conversationId, briefId, answer) =>
    axiosI.post(
        `${conf.api}/conversations/${conversationId}/briefs/${briefId}/answer`,
        {
            answer,
        },
        getHeaders(),
    );

export const fetchPostsTopHashtags = async (globalFilters: GlobalFilters) => {
    const { start_at, end_at } = getTimeRangeParameters(globalFilters.timeRangeFilter);

    const socialNetworks = Object.keys(globalFilters.socialNetworksFilter).map(
        (i) => globalFilters.socialNetworksFilter[i],
    );
    let networks = socialNetworks.filter(({ selected }) => selected === true).map((item) => item.label);
    if (networks.length === 0) {
        networks = socialNetworks.map((item) => item.label);
    }

    const params = {
        social_networks: networks,
        start_at,
        end_at,
    };
    return axiosI.get(`${conf.api}/posts/top_hashtags`, {
        headers: getHeaders().headers,
        params,
    });
};

export const fetchTopFlopPosts = async (globalFilters: GlobalFilters) => {
    const { start_at, end_at } = getTimeRangeParameters(globalFilters.timeRangeFilter);
    const account_ids = getAccountIdsParameters(globalFilters.socialNetworksFilter);

    const params = {
        start_at,
        end_at,
        account_ids,
    };

    return axiosI.get(`${conf.api}/posts/top_flop`, {
        headers: getHeaders().headers,
        params,
    });
};

export const fetchSnaTopPosts = async (params: SNATopPostsParams) => {
    const { start_at, end_at } = getTimeRangeParameters(params.timeRange);
    const account_ids = [params.snaId];

    return axiosI.get(`${conf.api}/posts/top_flop`, {
        headers: getHeaders().headers,
        params: { start_at, end_at, account_ids },
    });
};

export const fetchVisits = (timeRange: TimeRange): AxiosPromise<number> => {
    return axiosI.get(`${conf.api}/profiles/self/visits_count`, {
        headers: getHeaders().headers,
        params: {
            start_at: getTimeRangeParameters(timeRange).start_at,
        },
    });
};

export const fetchVisitsStartingFrom = (date: Date): AxiosPromise<number> => {
    return axiosI.get(`${conf.api}/profiles/self/visits_count`, {
        headers: getHeaders().headers,
        params: {
            start_at: moment(date).format(),
        },
    });
};

export const deleteUser = (): AxiosPromise<any> => {
    return axiosI.delete(`${conf.api}/user`, {
        headers: getHeaders().headers,
    });
};

export const fetchStatsSharing = (): AxiosPromise<FetchStatsSharing> => {
    return axiosI.get(`${conf.api}/stats_sharing`, {
        headers: getHeaders().headers,
    });
};

export const getRequestStatsSharingInfos = (requestId): AxiosPromise<FetchRequestStatsSharingInfos> => {
    return axiosI.get(`${conf.api}/stats_sharing`, {
        headers: getHeaders().headers,
        params: {
            stats_sharing_request_id: requestId,
        },
    });
};

export const fetchConversations = (params): AxiosPromise<FetchConversations> => {
    return axiosI.get(`${conf.api}/conversations`, {
        headers: getHeaders().headers,
        params,
    });
};

export const getConversationInfos = (conversationId: number): AxiosPromise<FetchConversationInfos> => {
    return axiosI.get(`${conf.api}/conversations/${conversationId}`, {
        headers: getHeaders().headers,
    });
};

export const getAllMessages = (params: FetchAllMessagesParams): AxiosPromise<FetchAllMessages> => {
    return axiosI.get(`${conf.api}/conversations/${params.id}/messages`, {
        headers: getHeaders().headers,
        params: {
            page: params.page,
            per_page: params.per_page,
        }
    });
}

export const postMessage = (id, message): AxiosPromise<FetchMessage> => {
    return axiosI.post(
        `${conf.api}/conversations/${id}/messages`,
        {
            message
        },
        getHeaders(),
    );
};

export const getDraftMessages = (params): AxiosPromise<FetchDraftMessages> => {
    return axios.get(`${conf.api}/draft_messages`, {
        headers: getHeaders().headers,
        params
    });
};

export const postDraftMessage = (message): AxiosPromise<FetchDraftMessage> => {
    return axiosI.post(
        `${conf.api}/draft_messages`,
        {
            draft_message: message
        },
        getHeaders(),
    );
};

export const putDraftMessage = (id, message): AxiosPromise<FetchDraftMessage> => {
    return axiosI.put(
        `${conf.api}/draft_messages/${id}`,
        {
            draft_message: message
        },
        getHeaders(),
    );
};

export const deleteDraftMessage = (id): ApiPromise<void> => axios.delete(`${conf.api}/draft_messages/${id}`, getHeaders());

export const putIgnoredInstaconnect = (isIgnored: boolean) => {
    return axios.put(
        `${conf.api}/profiles/self`,
        {
            is_instaconnect_ignored: isIgnored
        },
        getHeaders(),
    )
};

const postLegalTermSignature = (): AxiosPromise<{ is_legal_terms_signed: boolean }> => {
  return axios.post(`${conf.apiRoot}/v1/users/self/legal_term_signatures`, {}, getHeaders());
};

export const api: Api = {
    fetchSnaDataChart,
    fetchPosts,
    fetchCommunity,
    fetchHeader,
    saveFacebookPages,
    fetchPostsTopHashtags,
    fetchTopFlopPosts,
    login,
    register,
    verifyToken,
    sendConfirmationRegister,
    sendNewPassword,
    resetForgottenPassword,
    deleteUserLogout,
    postSnaRequest,
    postUnsupportedSnaRequest,
    removeUnsupportedSna,
    fetchInfoSna,
    postProfileRequest,
    putUserUpdateLocale,
    fetchAppInit,
    postAnswer,
    fetchProposition,
    fetchSnas,
    fetchSna,
    fetchProfileSelf,
    putProfileSelf,
    putEmail,
    resendConfirmationEmail,
    putSettingsChangePassword,
    saveUserToken,
    saveInstagramPages,
    removeSna,
    postAnswerBrief,
    fetchPropositions,
    fetchVisits,
    fetchVisitsStartingFrom,
    deleteUser,
    toggleUserMobilisationEnlisting,
    fetchSnaTopPosts,
    fetchStatsSharing,
    getRequestStatsSharingInfos,
    fetchConversations,
    getConversationInfos,
    getAllMessages,
    postMessage,
    getDraftMessages,
    postDraftMessage,
    putDraftMessage,
    deleteDraftMessage,
    putIgnoredInstaconnect,
    postLegalTermSignature,
};
