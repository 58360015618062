import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import { AnyAction, Action } from 'redux';
import * as PROFILE from 'constants/profile';
import * as SOCIAL_STATS_CARD from 'constants/socialStatsCard';
import { ProfileState, Reducers, UpdateFieldAction, OnEditActionPayload } from './profile.reducer.d';

const initialState: ProfileState = {
    loading: false,
    profile: {
        stageName: '',
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        addresses: [],
        livingAddress: {
            placeSearched: '',
            mapped: {},
        },
        nationality: [],
        phone: '',
        activities: [],
        influenceThemes: [],
        website: '',
        bio: '',
        description: '',
        nbVisits: 0,
    },
};

const reducers: Reducers = {
    [PROFILE.LOAD_INIT]: (state: ProfileState) => update(state, { loading: { $set: true } }),
    [PROFILE.LOAD_COMPLETE]: (state: ProfileState, { payload: { profile } }: AnyAction) =>
        update(state, {
            loading: { $set: false },
            profile: { $merge: profile },
        }),
    [PROFILE.FIELD_UPDATE]: (state: ProfileState, { payload: { field, value } }: UpdateFieldAction) =>
        update<ProfileState>(state, {
            profile: {
                [field]: { $set: value },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_ON_EDIT]: (state: ProfileState, { payload }: OnEditActionPayload): ProfileState => ({
        ...state,
        profile: {
            ...state.profile,
            website: payload.website.url,
        },
    }),
};

export default createReducer<ProfileState, Action<Reducers>>(initialState, reducers);
