import React, { FC, memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLexique, useDispatch, useSelector } from 'utils/redux';
import { PartenariatsPageLexique } from 'locales/types/containers/partenariatsPage';
import { PostEstimationValueCards, SocialStatsList } from 'components/templates';
import { AppState } from 'reducers';
import { getNbVisits } from 'actions/profile';
import { TimeRange } from 'types/Filters';
import { TimeRangeSelector } from 'components/molecules';
import './PartenariatsPage.scss';

interface State {
    nbVisits: number;
}

const PartenariatsPage: FC = () => {
    const lexique = useLexique<PartenariatsPageLexique>('containers.partenariatsPage');
    const dispatch = useDispatch();
    const { nbVisits } = useSelector<State>((state: AppState) => ({
        nbVisits: state.profile.profile && state.profile.profile.nbVisits,
    }));
    const defaultRange: TimeRange = TimeRange.oneMonth;
    const [range, setRange] = useState<TimeRange>(defaultRange);
    useEffect(() => {
        dispatch(getNbVisits(range));
    }, [range, dispatch]);
    return (
        <div className="bc-analytics-stats partenariats">
            <Helmet>
                <title>{lexique.headTitle}</title>
            </Helmet>
            <div className="bc-analytics-stats__title">{lexique.headTitle}</div>
            <SocialStatsList />
            <div className="partenariats__content">
                <PostEstimationValueCards />
                <header className="partenariats__content__header">
                    <h3 className="partenariats__content__title">{lexique.nbViews}</h3>
                    <div className="filter">
                        <p className="label">{lexique.concernedPeriod}</p>
                        <TimeRangeSelector timeRange={range} onTimeRangeChange={setRange} canBeAllTime={false} />
                    </div>
                </header>
                <div className="partenariats__content__nbvisits">{nbVisits}</div>
            </div>
        </div>
    );
};

export default memo(PartenariatsPage);
